@font-face {
    font-family: 'fs_elliot_pro';
    src: url('fselliotpro-webfont.woff2') format('woff2'),
         url('fselliotpro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fs_elliot_pro';
    src: url('fselliotpro-bold-webfont.woff2') format('woff2'),
         url('fselliotpro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'fs_elliot_pro';
    src: url('fselliotpro-heavy-webfont.woff2') format('woff2'),
         url('fselliotpro-heavy-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
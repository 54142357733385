*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html, document, body, #root, #root, .page {
  min-height: 100%;
  scroll-behavior: auto;
  overflow-anchor: auto;
}
main {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.page-content {
  background-color: #0A0A0A;
}
body {
  margin: 0;
  padding: 0;
  font-family: "fs_elliot_pro";
  color: #fff;
}

a {
  text-decoration: none;
}

figure, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

html.modal-open {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}

h2 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 900;
}
h3 {
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
}
h4 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: normal;
}

h5, h6 {
  font-size: 16px;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.text-orange {
  color: #E74E1A;
}
.text-semiwhite {
  color: #8E8E8E;
}
.text-white {
  color: #fff;
}
.content-container img {
  max-width: 100%;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.col50 {
  float: left;
  width: 50%;
}

.block440w {
  width: 440px;
  margin: 0 auto;
}
form .main-btn {
  margin-bottom: 20px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}

/* solid-botom border */

hr.solid {
  margin: 0;
  border: 0;
  width: 100%;
  height: 1px;
  display: block;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #333 50%, rgba(0,0,0,0) 100%);
}

/* dashed-bottom border */ 


hr.dashed {
  margin: 0;
  border: 0;
  width: 100%;
  height: 2px;
  display: block;
  background-image: linear-gradient(90deg, #333, #555 30%, transparent 40%, transparent 100%);
  background-size: 6px;
}

/* footer & header container */

.wide-container {
  display: grid;
  padding: 0 .5rem;
  grid-template-columns: minmax(300px, 1700px);
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .wide-container{
    padding: 0 2rem;
  }
}

/* bootstrap classes */

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 1230px;
  max-width: 100%;
}
.container-fw {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1C1C1C inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #FFF !important;
}

/* sidebar media, button */

.sidebar {
  width: 100vw;
  background-color: #000;
}

.mobile-button-wrap {
  position: fixed;
  width: 100%;
  padding: 0 10px;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.mobile-button-wrap button {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
}

.mobile-button {
  position: fixed;
  bottom: 0;
  z-index: 12;
  left: 0;
  padding: 0 15px;
}

.mobile-button .button {
  box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.mobile-cart__wrap {
  background-color: #0A0A0A;
  height: 2.7rem;
}

.mobile-cart__submit {
  position: fixed;
  bottom: 10px;
  z-index: 12;
}

.mobile-cart__submit > .button {
  border-radius: 4px !important;
  box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
}

@media all and (min-width: 768px) {

  .mobile-button-wrap {
    position: static;
    width: auto;
    padding: 0;
  }

  .mobile-button-wrap  button {
    width: auto;
    border-radius: 4px;
    box-shadow: none;
  }

  .mobile-button {
    position: static;
    padding: 0;
  }
  .mobile-button .button {
    border-radius: 4px;
    box-shadow: none;
    width: auto;
  }

  .sidebar {
    width: 50vw;
  }

  /*html {
    scroll-behavior: smooth;
  }*/
}

.no-scroll {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .sidebar {
    width: 35vw;
  }
}
